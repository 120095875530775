jQuery(document).ready(function ($) {
  // CLICK FUNCTIES +++++++++++++++++++++++++++ //

  $(function () {
    $("html").on("click", function (e) {
      let $t = $(e.target),
        $myLinks = $(".nav-collapse-menu"),
        $myLinksinner = $(".nav-collapse-menu .inner"),
        $toggleMenu = $(".hamburger-menu-trig");
      if ($t.is($myLinksinner) || $myLinksinner.has($t).length) {
        //clicked in the menu. do nothing
      } else if ($t.is($toggleMenu) || $toggleMenu.has($t).length) {
        $myLinks.toggleClass("actief");
        $("body").toggleClass("overlay-bg");
      } else {
        $myLinks.removeClass("actief");
        $("body").removeClass("overlay-bg");
      }
    });
  });

  // Algemene toggle -----------
  $(".toggle").on("click", function () {
    $(this).toggleClass("actief");
  });

  // Actieveren popup -----------
  $(".trigger-nieuwsbrief").on("click", function () {
    $(".popup-nieuwsbrief").addClass("toon");
    $("body").addClass("noscroll");
  });
  $(".inner .sluiten ,body").on("click", function () {
    $(".popup-nieuwsbrief").removeClass("toon");
    $("body").removeClass("noscroll");
  });
  $(".trigger-nieuwsbrief,.popup-nieuwsbrief .inner").click(function (e) {
    e.stopPropagation();
  });

  // sidepop-nb
  $(".sidepop-nb .sluiten").click(function () {
    $(".sidepop-nb").addClass("hidden");
    var now = new Date();
    // Add 5 days to the current date
    now.setTime(now.getTime() + 14 * 24 * 60 * 60 * 1000);
    var expires = now.toUTCString();
    document.cookie = "sidepop_nb_hide=true;expires=" + expires + ";path=/";
  });
  if (Cookies.get("sidepop_nb_hide")) {
    $(".sidepop-nb").addClass("keep-hidden");
  }

  // COOKIES ++++++++++++++++++++++++++ //

  // melding-popup
  $(".melding-popup .sluiten").click(function () {
    $(".melding-popup").addClass("hidden");
    Cookies.set("melding-popup.hide", "true", { expires: 7 });
  });
  if (Cookies.get("melding-popup.hide")) {
    $(".melding-popup").addClass("keep-hidden");
  }

  // melding-boven
  $(".melding-boven .sluiten").click(function () {
    $(".melding-boven").addClass("hidden");
    Cookies.set("melding-boven.hide", "true", { element: ".melding-boven" }, { expires: 7 });
  });
  if (Cookies.get("melding-boven.hide")) {
    $(".melding-boven").addClass("keep-hidden");
  }

  // SCROLL FUNCTIES ++++++++++++++++++++++++++ //

  // Back to top -----------
  $(".backtotop").click(function () {
    $("html, body").animate({ scrollTop: 0 }, 600);
    return false;
  });

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if ($(window).scrollTop() > 400) {
      $(".backtotop").addClass("toon");
    } else if ($(window).scrollTop() <= 400) {
      $(".backtotop").removeClass("toon");
    }
  });

  $(window).scroll(function () {
    if ($(window).scrollTop() + $(window).height() == $(document).height()) {
      $(".backtotop").addClass("fix");
    } else {
      $(".backtotop").removeClass("fix");
    }
  });

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if ($(window).scrollTop() > 400) {
      $(".sidepop-nb").addClass("toon");
    }
  });

  // VASTE VENDORS +++++++++++++++++++++++++++ //

  // Toewijzen justified gallery -----------
  if ($(".acf-justigal")[0]) {
    $(".acf-justigal").justifiedGallery();
  }

  // CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
  //-----------------------------------------------------------------------------------------------------------//

  // breadcrumb wrap

  $(".acc").text(">");

  // accordion
  $(".accordion > .acc-item:first-of-type").addClass("toon");

  $(".accordion > .acc-item .vraag").click(function (e) {
    e.preventDefault();

    var $this = $(this);

    if ($this.parent().hasClass("toon")) {
      $this.parent().removeClass("toon");
    } else {
      $(".accordion > .acc-item").removeClass("toon");
      $this.parent().toggleClass("toon");
    }
  });

  // smooth anker scroll

  $("a[rel=scroll]").click(function (event) {
    //prevent the default action for the click event
    event.preventDefault();

    //get the full url - like mysitecom/index.htm#home
    var full_url = this.href;

    //split the url by # and get the anchor target name - home in mysitecom/index.htm#home
    var parts = full_url.split("#");
    var trgt = parts[1];

    //get the top offset of the target anchor
    var target_offset = $("#" + trgt).offset();
    var target_top = target_offset.top;
    target_top = target_top - 50;

    //goto that anchor by setting the body scroll top to anchor top
    $("html, body").animate({ scrollTop: target_top }, 500, "easeInSine");
  });

  //swiper-projecten --------------------------------------
  var swiper = new Swiper(".swiper-projecten", {
    autoplay: { delay: 5000 },
    speed: 300,
    autoHeight: false,
    grabCursor: true,
    loop: true,
    watchOverflow: true,
    centeredSlides: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    direction: "horizontal",
    effect: "slide", // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
    breakpoints: {
      // breakpoints gelden voor alles erboven
      0: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      767: {
        slidesPerView: "auto",
        spaceBetween: 10,
      },
      992: {
        slidesPerView: "auto",
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".projecten-next",
      prevEl: ".projecten-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets", // "bullets", "fraction", "progressbar"
      clickable: true,
    },
  });

  //swiper-gerelateerd --------------------------------------
  var swiper = new Swiper(".swiper-gerelateerd", {
    autoplay: { delay: 5000 },
    speed: 300,
    autoHeight: false,
    grabCursor: true,
    loop: false,
    watchOverflow: true,
    centeredSlides: false,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    direction: "horizontal",
    effect: "slide", // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
    breakpoints: {
      // breakpoints gelden voor alles erboven
      0: {
        slidesPerView: "auto",
        spaceBetween: 10,
      },
      767: {
        slidesPerView: "auto",
        spaceBetween: 10,
      },
      992: {
        slidesPerView: "auto",
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".gerelateerd-next",
      prevEl: ".gerelateerd-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets", // "bullets", "fraction", "progressbar"
      clickable: true,
    },
  });

  // swipers pomblad ------------------------------
  if (typeof flex_gallery_index !== "undefined") {
    var swiper_flexgal = [];
    for (var i = 0; i <= flex_gallery_index; i++) {
      // create a unique swiper for each flexible gallery fields
      swiper_flexgal[i] = new Swiper(".swiper-pomblad-" + i, {
        //autoplay: {delay: 5000,},
        speed: 300,
        autoHeight: false,
        grabCursor: true,
        loop: false,
        watchOverflow: true,
        centeredSlides: false,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        direction: "horizontal",
        effect: "slide", // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
        breakpoints: {
          // breakpoints gelden voor alles erboven
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        },

        navigation: {
          nextEl: ".pomblad-" + i + "-next",
          prevEl: ".pomblad-" + i + "-prev",
        },
      });
    }
  }
});
